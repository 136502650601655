<template>
  <div>
    <!-- Title -->
    <div class="e_store-form-title mb-7">{{ t('forgot_password') }}</div>

    <!-- Request By Email -->
    <div v-if="form.method === 'Email'">
      <AuthTextField
        v-model="form.email"
        :label="t('email')"
        placeholder="example@email.com"
        type="email"
        error="forgot-email"
      />
      <div
        class="text-nav_color text-sm font-medium cursor-pointer"
        @click="form.method = 'Phone'"
      >
        {{ t('recover_with', { with: t('phone') }) }}
      </div>
    </div>

    <!-- Request By Phone -->
    <div v-else-if="form.method == 'Phone'">
      <LazyAuthPhoneField
        id="forgot-phone-input"
        v-model="form.phone"
        error="forgot-phone"
      />

      <div
        class="text-nav_color text-sm font-medium cursor-pointer"
        @click="form.method = 'Email'"
      >
        {{ t('recover_with', { with: t('email') }) }}
      </div>
    </div>

    <div class="h-6"></div>

    <!-- Submit -->
    <EStoreResponseError
      class="my-2"
      api-key="forgot"
      :except="['forgot-email', 'forgot-phone']"
    />
    <EStoreSubmitButton
      class="my-2"
      :loading="form.loading"
      @submit="forgotHandler"
    />
  </div>
</template>

<script setup lang="ts">
definePageMeta({ auth: false })

const { form, forgotHandler } = useForgot()
const { url } = useDomainHost()
const { t } = useI18n()
const { store } = useDomainState()

useServerSeoMeta(
  useOgMeta(
    url + '/auth/forgot',
    t('forgot_password'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('forgot_password'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/auth/forgot')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('forgot_password'), url + '/auth/forgot')
    ])
  ]
})
</script>
